<template>
  <div class="index-view">
    <v-container>
      <v-btn to="/rinnovo-tessera">
        <span>Rinnovo tessera</span>
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>
