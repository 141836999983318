<template>
    <div class="rinnovo-tessera-view">
        <v-container>
            <v-sheet
            max-width="768"
            class="mx-auto">
                <v-sheet min-height="70">
                    <v-alert
                    v-if="$route.query.paypal_env === 'sandbox'"
                    type="warning"
                    border="start"
                    closable
                    density="comfortable"
                    variant="tonal"
                    class="mb-4">Paypal in sandbox mode</v-alert>

                    <v-alert
                    v-if="success"
                    type="success"
                    border="start"
                    closable
                    density="comfortable"
                    variant="tonal"
                    class="mb-4">{{ success }}</v-alert>

                    <v-alert
                    v-if="info"
                    type="info"
                    border="start"
                    closable
                    density="comfortable"
                    variant="tonal"
                    class="mb-4">{{ info }}</v-alert>

                    <v-alert
                    v-if="error"
                    type="error"
                    border="start"
                    closable
                    density="comfortable"
                    variant="tonal"
                    icon="mdi-alert-circle"
                    class="mb-4">{{ error }}</v-alert>
                </v-sheet>

                <!-- <v-form
                v-if="step === 'recovery_auth'"
                ref="form"
                fast-fail
                @submit.prevent="submit">
                    <v-text-field
                    v-model="model.email"
                    :rules="[rules.required]"
                    density="compact"
                    variant="outlined"
                    label="* Email" />

                    <div class="mt-4">
                        <v-btn
                        type="submit"
                        :loading="loading"
                        :disabled="loading"
                        block
                        variant="tonal">Avanti</v-btn>
                    </div>
                </v-form>

                <v-form
                v-if="step === 'confirm_auth'"
                ref="form"
                fast-fail
                @submit.prevent="submit">
                    <v-otp-inpute
                    v-model="model.auth_code"
                    :rules="[rules.required]"
                    density="compact"
                    variant="outlined"
                    label="* Email" />

                    <v-btn
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                    block
                    variant="tonal"
                    class="mt-4">Avanti</v-btn>
                </v-form> -->

                <!--  -->
                <v-form
                ref="form"
                fast-fail
                @submit.prevent="submit">
                    <v-row class="mb-3">
                        <v-col cols="12" sm="6">
                            <v-card
                            min-height="180"
                            variant="outlined"
                            @click="model.card_type = 'tessera'">
                                <v-card-title class="d-flex">
                                    <span>Rinnovo tessera</span>
                                    <v-chip
                                    variant="outlined"
                                    class="ml-2">15,00 €</v-chip>

                                    <v-spacer></v-spacer>

                                    <v-icon>{{ model.card_type === 'tessera' ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
                                </v-card-title>

                                <v-card-text class="mt-2">
                                    <small>Con la tessera attivi il servizio di condivisione della biblioteca degli oggetti con cui condividere e prendere in prestito tutti gli oggetti che vuoi. Rinnovi anche l'assicurazione, che ti permette di partecipare a qualsiasi attività di Leila come laboratori, workshop, Leila Social Club, ecc.</small>
                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-card
                            min-height="180"
                            variant="outlined"
                            @click="model.card_type = 'mezza_tessera'">
                                <v-card-title class="d-flex">
                                    <span>Rinnovo mezza tessera</span>
                                    <v-chip
                                    variant="outlined"
                                    class="ml-2">10,00 €</v-chip>

                                    <v-spacer></v-spacer>

                                    <v-icon>{{ model.card_type === 'mezza_tessera' ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
                                </v-card-title>

                                <v-card-text class="mt-2">
                                    <small>Con la mezza tessera potrai beneficiare dell'assicurazione, senza però poter partecipare al servizio di condivisione della biblioteca degli oggetti.</small>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- <v-text-field
                    v-model="model.name"
                    density="compact"
                    variant="outlined"
                    label="Nome e Cognome"
                    :rules="[rules.required]" /> -->

                    <v-text-field
                    v-model="model.email"
                    :rules="[rules.required, rules.email]"
                    density="compact"
                    variant="outlined"
                    label="* Email" />

                    <v-text-field
                    v-model.number="model.card_number"
                    :rules="[rules.required, rules.only_numbers]"
                    type="number"
                    min="0"
                    step="1"
                    density="compact"
                    variant="outlined"
                    label="* Numero Tessera" />

                    <v-btn
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                    block
                    flat
                    color="#4F8D9F"
                    class="mt-2">Vai al pagamento con paypal</v-btn>
                </v-form>

                <!--  -->
                <v-alert
                v-if="error"
                type="error"
                density="comfortable"
                border="start"
                variant="tonal"
                closable
                icon="mdi-alert-circle"
                class="mt-4">{{ error }}</v-alert>

                <!--  -->
                <form
                ref="paypal_form"
                :action="paypal.action"
                method="post"
                target="_top">
                    <input type="hidden" name="image_url" value="https://webapp.leila-bologna.it/img/logo.png" />
                    <input type="hidden" name="business" :value="paypal.account" />
                    <input type="hidden" name="bn" value="smnfsc_x_leila" />
                    <input type="hidden" name="cmd" value="_cart" />
                    <input type="hidden" name="lc" value="it_IT" />
                    <input type="hidden" name="cbt" value="Leila Bologna" />
                    <input type="hidden" name="currency_code" value="EUR" />
                    <input type="hidden" name="no_shipping" value="1" />

                    <input type="hidden" name="cancel_return" :value="cancel_return_url" />
                    <input type="hidden" name="return" :value="paypal.return_url" />
                    <input type="hidden" name="rm" value="1">
                    <input type="hidden" name="notify_url" value="https://forms.leila-bologna.it/v1/users_renews/ipn" />

                    <input type="hidden" name="upload" value="1">

                    <input type="hidden" name="item_name_1" :value="model.card_type">
                    <input type="hidden" name="amount_1" :value="model.card_type === 'tessera' ? '15.00' : '10.00'" />
                    <input type="hidden" name="tax" value="0" />

                    <input type="hidden" name="custom" :value="paypal.custom_value" />
                </form>
            </v-sheet>
        </v-container>

        <v-overlay
        :model-value="loading"
        class="align-center justify-center">
            <v-progress-circular
            color="primary"
            overlay="0.9"
            indeterminate
            size="64" />
        </v-overlay>
    </div>
</template>

<script>
// https://www.sandbox.paypal.com/cgi-bin/webscr
// https://www.paypal.com/cgi-bin/webscr

// Personal 1
//     Leila Bologna
//     sb-fkn7a3886833@personal.example.com / donazione@leila-bologna.it
//     WZ9cU+ZO

// Personal 2
//     Antonio Beraldi
//     sb-0pua81639729@personal.example.com
//     u?99Pg0,
export default {
    name: 'rinnovo_tessera',
    data () {
        return {
            step: 'recovery_auth',
            loading: false,
            is_embedded: false,
            //
            error: null,
            info: null,
            success: null,
            //
            model: {
                // name: null,
                email: null,
                card_number: null,
                card_type: 'tessera',
                auth_code: null
            },
            paypal: {
                account: 'donazione@leila-bologna.it',
                action: 'https://www.paypal.com/cgi-bin/webscr',
                return_url: 'https://leila-bologna.it/tessera/?transaction_status=completed',
                cancel_return_url: 'https://leila-bologna.it/tessera/?transaction_status=canceled',
                custom_value: null
            },
            rules: {
                required: (value) => {
                    if (value) return true
                    return 'Campo richiesto'
                },
                only_numbers: v => /^\d+$/.test(v) || 'questo campo deve contere solo un numero intero',
                email: v => /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(v) || 'inserisci un\'email corretta'
            }
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
            // window.addEventListener('message', function(evt) {
            //     var origin = evt.origin || evt.originalEvent.origin // For Chrome, the origin property is in the event.originalEvent object.
            //     if (origin !== 'http://leila-bologna.it') return

            //     if (typeof event.data === 'object' && event.data.call === 'sendValue') {
            //         // Do something with event.data.value;
            //     }
            // }, false)

            // this.is_embedded = window.top.location.origin !== window.location.origin

            // const top_query_params = new URLSearchParams(window.top.location.search)
            // const top_transaction_status = top_query_params.get('transaction_status')
            // const top_payer_id = top_query_params.get('PayerID')

            // console.log(top_query_params, top_transaction_status, top_payer_id)
            console.log(this.$route.query)
            // console.log('is_embedded', this.is_embedded)

            if (this.$route.query.paypal_env === 'sandbox') {
                this.paypal.account = 'sb-fkn7a3886833@personal.example.com'
                this.paypal.action = 'https://www.sandbox.paypal.com/cgi-bin/webscr'
            }

            if (this.$route.query.PayerID) {
                this.$http.get(`v1/users_renews/ipn/${this.$route.query.PayerID}`).then((doc) => {
                    if (doc.payment.status === 'completed') {
                        this.success = `Ciao ${doc.user.name}, il rinnovo della tua tessera nr. ${doc.user.card_number} è stato completato, riceverai a breve la conferma via email, buona condivisione!`
                    } else {
                        this.error = `Ops, qualcosa è andato storto, stato del tuo rinnovo ${doc.payment.status}. Riprova più tardi o contattaci per ulteriore supporto.`
                    }
                }).catch(() => {})
            }

            if (this.$route.query.transaction_status === 'canceled') {
                this.info = 'Transazione annullata, riprova più tardi o contattaci per ulteriore supporto.'
            }

            this.$router.replace('/rinnovo-tessera')
        },
        async submit (a, b, c) {
            console.log('submit', a, b, c)
            console.log(this.$refs.form, this.$refs.paypal_form)

            const form = this.$refs.form
            if (!form.isValid) return

            this.success = null
            this.info = null
            this.error = null
            this.loading = true

            try {
                this.paypal.custom_value = await this.$http.post('/v1/users_renews', {
                    card: {
                        name: this.model.card_type === 'tessera' ? 'Tessera' : 'Mezza tessera',
                        type: this.model.card_type,
                        price: this.model.card_type === 'tessera' ? 15 : 10
                    },
                    payment: {
                        method: 'paypal'
                    },
                    ...this.model
                })

                await this.$nextTick()

                this.$refs.paypal_form.submit()
            } catch (err) {
                if (err === 'user.not_found') {
                    this.error = 'Ops, nessun membro trovato, verifica l\'email e il numero della tessera inseriti'
                } else {
                    this.error = err
                }

                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>