import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Http from './assets/libs/Http'

const app = createApp(App)
const http = new Http({
  base_url: process.env.VUE_APP_API,
  app_id: 'leila-saas',
  prefix: 'leila-s'
})

app.config.globalProperties.$http = reactive(http)

loadFonts()

app
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
