import axios from 'axios'
import { parse, stringify } from 'qs'

export default class HTTP {
    constructor(config = {}) {
        this.config = config

        this.http = axios.create({
            baseURL: this.config.base_url,
            withCredentials: true,
            timeout: this.config.timeout || 180000,
            headers: {
                'x-app-id': this.config.app_id
            },
            paramsSerializer: {
                encode: parse,
                serialize: (params) => {
                    return stringify(params, {
                        arrayFormat: 'repeat',
                        indices: false,
                        allowDots: false,
                        skipNulls: true,
                        format: 'RFC1738'
                    })
                }
            },
            validateStatus: this.config.validate_status || (status => status >= 200 && status < 400)
        })

        return this
    }

    // publics

    option (resource, params) {
        return new Promise((resolve, reject) => {
            this.http.options(resource, { params: params }).then((res) => {
                return resolve(res.data)
            }).catch((err) => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    get(resource, params) {
        return new Promise((resolve, reject) => {
            this.http.get(resource, { params: params }).then((res) => {
                return resolve(res.data)
            }).catch((err) => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    post(resource, data, headers) {
        return new Promise((resolve, reject) => {
            return this.http.post(resource, data, { headers: headers }).then((res) => {
                return resolve(res.data)
            }).catch((err) => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    put(resource, data) {
        return new Promise((resolve, reject) => {
            return this.http.put(resource, data).then((res) => {
                return resolve(res.data)
            }).catch((err) => {
                reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    patch(resource, data) {
        return new Promise((resolve, reject) => {
            return this.http.patch(resource, data).then((res) => {
                return resolve(res.data)
            }).catch(err => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }

    delete(resource, data, headers) {
        return new Promise((resolve, reject) => {
            return this.http.delete(resource, {
                data: data,
                headers: headers
            }).then(res => {
                return resolve(res.data)
            }).catch((err) => {
                return reject(err.response && err.response.data ? err.response.data : err.response)
            })
        })
    }
}
